import { MemoriaAnualPopupComponent } from './../memoria-anual-popup/memoria-anual-popup.component';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DropdownService } from 'src/app/services/dropdown.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { SelectOptions } from 'src/app/models/select-options.model';
import { ActivityService } from 'src/app/services/activity.service';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AddMultipleActivitiesComponent } from '../../popups/add-multiple-activities/add-multiple-activities.component';
import { MultipleActivityService } from 'src/app/services/multiple-activity.service';
import { ActivityRoot } from 'src/app/models/activity.save.activityroot';
import { UsuarioUpsert } from 'src/app/models/activity.save.usuarioupsert';
import { EspecialidadTecnica } from 'src/app/models/activity.save.especialidadtecnica';
import { NgxSpinnerService } from 'ngx-spinner';
import { LogActivityService } from 'src/app/services/_logActivity/log-activity.service';
import { UserPermissionService } from 'src/app/services/user-permission-service';
import { FilterService } from 'src/app/services/filter.service';
import { ParteDeVisitaState } from 'src/app/models/Enum/ParteDeVisitaState';
import { MasterDataService } from 'src/app/services/master-data.service';
import { AnnualShippingMicroSMEComponent } from '../annual-shipping-micro-sme/annual-shipping-micro-sme.component';
import moment from 'moment/moment';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { Constants } from 'src/app/Helpers/constant';
import { PreventionPlanPopupComponent } from '../prevention-plan-popup/prevention-plan-popup.component';
import { MemoriaAnualWithQuizPopupComponent } from '../memoria-anual-popup/memoria-anual-with-quiz-popup.component';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-add-edit-activity',
  templateUrl: './add-edit-activity.component.html',
  styleUrls: ['./add-edit-activity.component.scss']
})
export class AddEditActivityComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  private currentDate = new Date();
  selectedCompanyId: any;
  @Input() public addEditActivityForm = new FormGroup({
    Id: new FormControl(0),
    Alias: new FormControl(null),
    ActivityTypeId: new FormControl(null, Validators.required),
    ActivtyTypeName: new FormControl(null),
    CompanyId: new FormControl(null, Validators.required),
    CompanyName: new FormControl(null),
    WorkCentreId: new FormControl(null, Validators.required),
    WorkCentreName: new FormControl(null),
    AssignedUserId: new FormControl(null, Validators.required),
    AssignedUsername: new FormControl(null),
    StatusTypeId: new FormControl(null, Validators.required),
    StatusTypeName: new FormControl(null),
    ActivityDate: new FormControl(this.currentDate, Validators.required),
    ActivityStartTime: new FormControl(null, Validators.required),
    ActivityEndTime: new FormControl(null, Validators.required),
    
    Duration: new FormControl({ disabled: true, value: null }, Validators.required),
    Description: new FormControl('')
  },{ validators: this.dateComparisonValidator });
  @Input() public isEdit = false;
  @Input() public isVisit = false;
  @Input() public isAnnual = false;
  @Input() public isMultipleActivityPopUpBtnEnabled: boolean = false;
  @Input() public isSubmitBtnEnabled: boolean = false;
  @Input() public isDataModificationAllowed: boolean = false;
  @Input() public isDataDeletionAllowed: boolean = false;

  public ParteDeVisitaStates = ParteDeVisitaState;
  public SelectedParteDeVisitaState: ParteDeVisitaState;
  private SelectedVisitaReferenceNo: string;

  isMemPermited: boolean = false;
  isProgPermited: boolean = false;
  formResetToggle = true;
  activityTypes$: SelectOptions[];
  companies$: SelectOptions[];
  workingCentres$: SelectOptions[];
  users$: SelectOptions[];
  activityStatusTypes$: SelectOptions[];
  currentSelectedStatus: string;
  showEnvio: boolean = true;
  showElaboracion: boolean = true;
  emailAddress = [];
  companyCodigo: string;
  user: any;

  public isAnualProgramme = false;
  public isPreventionPlan = false;

  modalTitle = 'Actuación';
  modalOptions: NgbModalOptions;

  private activityRoot: ActivityRoot;
  private activityUserDetails: UsuarioUpsert;
  private activityDetails: EspecialidadTecnica;
  private clientReqIPAddress = '';
  private activitySaveState = false;

  // 2420390-20051017161914-0001-W - Default Spanish Language Id
  private languageId = '2420390-20051017161914-0001-W';

  private companySubject: Subject<string> = new Subject();
  private centerSubject: Subject<string> = new Subject();
  private typeOfActivitySubject: Subject<string> = new Subject();
  private userSubject: Subject<string> = new Subject();


  constructor(private dropdownService: DropdownService,
    private errorHandler: ErrorHandlerService,
    private activityService: ActivityService,
    public addActivityModal: NgbActiveModal,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private multipleActivityService: MultipleActivityService,
    private spinner: NgxSpinnerService,
    private logActivityService: LogActivityService,
    private userPermissionService: UserPermissionService,
    private filterService: FilterService,
    private masterDataService: MasterDataService,
    private dataSharingService:DataSharingService) {
    this.modalOptions = {
      backdrop: 'static',
      windowClass: 'text-left modal-fade-animation',
      centered: true,
      size: 'lg'
    };

    this.user = JSON.parse(localStorage.getItem('loggeduser'));
  }

  dateComparisonValidator(group: FormGroup) {
    const startDate = new Date(group.get('ActivityStartTime').value);
    const endDate = new Date(group.get('ActivityEndTime').value);
        
    let dtStart = startDate.getTime();
    let dtFinish = endDate.getTime();

    if (dtStart && dtFinish && dtFinish < dtStart) {
      return { dateRange: true };
      //group.get('ActivityEndTime').setErrors({ dateMismatch: true });
    } 
    // else {
    //   group.get('ActivityEndTime').setErrors(null);
    // }

    return null;
  }

  ngOnInit() {
    this.getRolePermission();
    const formDate: Date = this.addEditActivityForm.value.ActivityDate;
    const activityDate = `${formDate.getDate().toString()}/${String(formDate.getMonth() + 1)}/${formDate.getFullYear().toString()}`;
    this.masterDataService
      .getVisitRefrenceNumber(activityDate, this.addEditActivityForm.value.WorkCentreId)
      .subscribe(reference => {
        this.SelectedVisitaReferenceNo = reference;
        localStorage.setItem("reference", reference);
      })
    this.showEnvio = true;
    this.initDropdowns();
    //this.enableSubmitBtn();
    if (!this.isEdit) {
      this.setDefaultValues();      
      this.modalTitle = 'Añadir Actuación';
    }
    // Hide/show delete & submit button based on user permission
    if (this.isEdit) {
      this.isDataModificationAllowed = this.userPermissionService.isActuacionUpdateAllowed;
      this.isDataDeletionAllowed = this.userPermissionService.isActuacionDeleteAllowed;
    } else {
      this.isDataModificationAllowed = this.userPermissionService.isActuacionInsertAllowed;
      this.isDataDeletionAllowed = false;
    }

    this.companySubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(companyName => this.loadTopMatchingCompanies(companyName));

    this.centerSubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged()
    ).subscribe(centerName => this.loadTopMatchingWorkcentres(centerName));

    this.typeOfActivitySubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged()
    ).subscribe(typeOfActivity => {
        this.loadTopMatchingActivityTypes(typeOfActivity)
      });

    this.userSubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged()
    ).subscribe(user => this.loadTopMatchingUsers(user));
  }

  preventKeyboard(event) {
    if (event.key !== 'Tab' && event.key !== 'Enter') {
      event.preventDefault();
    }
  }

  getRolePermission() {
    this.isMemPermited = false;
    this.isProgPermited = false;

    let request = {
      UserId: this.user.id
    }
    this.masterDataService.getRolePermission(request).subscribe(res => {
      if (res) {
        res.forEach(item => {
          if (item.permissions.permisionCode == "SeeProgramacionAnualWizardButton") {
            this.isProgPermited = true;
            return;
          }
          else if (item.permissions.permisionCode == "SeeMemoriaAnualWizardButton") {
            this.isMemPermited = true;
            return;
          }
        });
      }
    });
  }

  initDropdowns() {

    this.spinner.show();
    let companyId = '';

    const selectionObjectCompany = { stringValue: '', fetchLimit: 100, idPart: '' };
    const sessionFilterObject = this.filterService.filterObject;
    if (this.isEdit) {
      companyId = this.addEditActivityForm.value.CompanyId;
    } else {
      companyId = (!sessionFilterObject.id_empresa || sessionFilterObject.id_empresa === '0') ? '' : sessionFilterObject.id_empresa;
      selectionObjectCompany.idPart = companyId;
      if (companyId === '') {
        selectionObjectCompany.stringValue = '-99';
        selectionObjectCompany.fetchLimit = 0;
      }
    }
    const activityTypeId = this.isEdit ? this.addEditActivityForm.value.ActivityTypeId : '';
    if (activityTypeId) {
      if (activityTypeId == "20192503000000-0000000-0046-W") {
        this.showEnvio = true;
      } else {
        this.showEnvio = false;
      }

      if (activityTypeId == "20151013114725-6267782-0059-W") {
        this.showElaboracion = true;
      } else {
        this.showElaboracion = false;
      }

    }
    const userId = this.isEdit ? this.addEditActivityForm.value.AssignedUserId : JSON.parse(localStorage.getItem('loggeduser')).id;



    /*NOTE:Client requirement=> Status type "11" is not available in client DB.Therefore set  "01" instead*/
    const actualStatusTypeId: string = this.addEditActivityForm.value.StatusTypeId === '11'
      ? '01' : this.addEditActivityForm.value.StatusTypeId;

    if (this.isEdit) {
      localStorage.setItem("selectedTipoActuation", activityTypeId);
      this.subs.add(


        this.dropdownService.getActivityTypes('', 100, activityTypeId).subscribe(response => {

          this.activityTypes$ = this.dropdownService.WrappedToDropDownList(response, 'name', 'activityTypeId');
          this.addEditActivityForm.value.ActivityTypeId = this.activityTypes$
            .filter(c => c.value === this.addEditActivityForm.value.ActivityTypeId).length === 0
            ? null : this.addEditActivityForm.value.ActivityTypeId;
          this.isVisit = this.addEditActivityForm.value.ActivtyTypeName.toString().toLowerCase().trim().includes('visita');
         
          if (this.isVisit) {

            const formDate: Date = this.addEditActivityForm.value.ActivityDate;
            const activityDate = `${formDate.getDate().toString()}/${String(formDate.getMonth() + 1)}/${formDate.getFullYear().toString()}`;
            this.masterDataService
              .getVisitRefrenceNumber(activityDate, this.addEditActivityForm.value.WorkCentreId)
              .subscribe(reference => {
                this.SelectedVisitaReferenceNo = reference;
                this.masterDataService.getVisitState(reference).subscribe(state => {
                  this.SelectedParteDeVisitaState = state;
                });
              });

          }

          if (this.addEditActivityForm.value.ActivityTypeId === '20192503000000-0000000-0046-W' || this.addEditActivityForm.value.ActivityTypeId === "20151013114725-6267782-0059-W") {
            this.isAnualProgramme = true;
          }
          
          this.isPreventionPlan = (this.addEditActivityForm.value.ActivtyTypeName.toLowerCase().includes('plan de prevención'));
        }, error => {
          this.errorHandler.handle(error);

        }),
        this.dropdownService.getCompanies('', 100, companyId).subscribe(response => {
          const res = response as Array<any>;
          const company = res.filter(c => c.companyId === this.addEditActivityForm.value.CompanyId)[0];

          // 2420390-20051017161914-0001-W - Spanish Language Id
          this.languageId = company ? company.commLanguage : "2420390-20051017161914-0001-W";

          this.companies$ = this.dropdownService.WrappedToDropDownList(response, 'name', 'companyId');
          this.addEditActivityForm.value.CompanyId = this.companies$
            .filter(c => c.value === this.addEditActivityForm.value.CompanyId).length === 0
            ? null : this.addEditActivityForm.value.CompanyId;
        }, error => {
          this.errorHandler.handle(error);
        }),

        this.dropdownService.getUsersLimited('', 100, userId).subscribe(response => {
          this.users$ = this.dropdownService.WrappedToDropDownList(response, 'fullName', 'id');
        }, error => {
          this.errorHandler.handle(error);
        }),
        this.dropdownService.getActivityStatusTypes(true).subscribe(response => {
          response = response.filter(x => x.deleted == false);
          this.activityStatusTypes$ = this.dropdownService.WrappedToDropDownList(response, 'statusTypeName', 'id', false, false, false, true, true);
          this.addEditActivityForm.patchValue({
            StatusTypeId: actualStatusTypeId
          });
        }, error => {
          this.errorHandler.handle(error);
        }),
        this.dropdownService.getClientReqIPAddress().subscribe(response => {
          this.clientReqIPAddress = response.clientReqIPAddress;
          this.spinner.hide();
        }, error => {
          this.errorHandler.handle(error);
          this.spinner.hide();
        }),
      );
    } else {
      this.subs.add(
        this.dropdownService.getCompanies(selectionObjectCompany.stringValue, selectionObjectCompany.fetchLimit, selectionObjectCompany.idPart).subscribe(response => {
          this.companies$ = this.dropdownService.WrappedToDropDownList(response, 'name', 'companyId');
          this.addEditActivityForm.value.CompanyId = this.companies$
            .filter(c => c.value === this.addEditActivityForm.value.CompanyId).length === 0
            ? null : this.addEditActivityForm.value.CompanyId;
          this.addEditActivityForm.patchValue({
            CompanyId: (companyId === '' ? null : companyId)
          });
        }, error => {
          this.errorHandler.handle(error);
        }),
        this.dropdownService.getUsersLimited('', 100, userId).subscribe(response => {
          this.users$ = this.dropdownService.WrappedToDropDownList(response, 'fullName', 'id');
        }, error => {
          this.errorHandler.handle(error);
        }),
        this.dropdownService.getActivityStatusTypes(true).subscribe(response => {
          response = response.filter(x => x.deleted == false);
          this.activityStatusTypes$ = this.dropdownService.WrappedToDropDownList(response, 'statusTypeName', 'id', false, false, true, false, true);
          const defaultStatusTypeId = this.activityStatusTypes$.filter(a => a.label === 'Validado').map(a => a.value).toString();
          this.addEditActivityForm.patchValue({
            StatusTypeId: defaultStatusTypeId
          });
        }, error => {
          this.errorHandler.handle(error);
        }),
        this.dropdownService.getClientReqIPAddress().subscribe(response => {
          this.clientReqIPAddress = response.clientReqIPAddress;
          this.spinner.hide();
        }, error => {
          this.errorHandler.handle(error);
          this.spinner.hide();
        }),
      );

      const wcValue = (!sessionFilterObject.id_centro || sessionFilterObject.id_centro === '0') ? null : sessionFilterObject.id_centro;
      this.addEditActivityForm.patchValue({
        WorkCentreId: wcValue
      });
    }

    if (companyId) { this.getCompanyWorkCentres(companyId, true); }

  }

  calculateDuration() {
    this.addEditActivityForm.get('Duration').setValue(0);
    let dtStart = new Date(this.addEditActivityForm.value.ActivityStartTime);
    let dtFinish = new Date(this.addEditActivityForm.value.ActivityEndTime);
  
    // Set both dates to a fixed date (e.g., January 1, 1970)
    let fixedDate = new Date(1970, 0, 1); // January 1, 1970
  
    dtStart.setFullYear(fixedDate.getFullYear(), fixedDate.getMonth(), fixedDate.getDate());
    dtFinish.setFullYear(fixedDate.getFullYear(), fixedDate.getMonth(), fixedDate.getDate());
  
    if (dtFinish > dtStart) {
      // Calculate duration in milliseconds
      const durationInMillis = dtFinish.getTime() - dtStart.getTime();
      // Convert milliseconds to hours and minutes
      let durationHours = Math.floor(durationInMillis / (1000 * 60 * 60));
      let durationMinutes = Math.floor((durationInMillis % (1000 * 60 * 60)) / (1000 * 60));
      // Calculate total duration in minutes
      this.addEditActivityForm.get('Duration').setValue(durationHours * 60 + durationMinutes);
    }
  }
  

  setDefaultValues() {
    const sessionStorageUser = JSON.parse(localStorage.getItem('loggeduser'));
    this.addEditActivityForm.patchValue({
      AssignedUserId: String(sessionStorageUser.id),
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getCompanyWorkCentres(selectedOption: any, isFirstPopUpLoad: boolean = false, wcNameSubString: string = '-99', isSearchMode: boolean = false) {
    let selectedOptionActual;
    if (selectedOption) {
      this.selectedCompanyId = selectedOption?.value ? selectedOption?.value : selectedOption;
      // For the first pop up load
      if (isFirstPopUpLoad) {
        let currentWorCentreId = (!this.addEditActivityForm.value.WorkCentreId || this.addEditActivityForm.value.WorkCentreId === '0')
          ? '' : this.addEditActivityForm.value.WorkCentreId;
        selectedOptionActual = selectedOption;
        this.dropdownService
          .getWorkingCentresByCompany(selectedOptionActual, wcNameSubString, 100, currentWorCentreId, isSearchMode).subscribe(response => {
            this.workingCentres$ = this.dropdownService.WrappedToDropDownList(response, 'centreName', 'id');
            // Ignore compilation error on 'value'
            currentWorCentreId = this.workingCentres$.filter(c => c.value === currentWorCentreId).length === 0 ? null : currentWorCentreId;
            this.addEditActivityForm.patchValue({
              WorkCentreId: currentWorCentreId
            });
          }, error => {
            this.errorHandler.handle(error);
          });

      } else { // When a company is selected explicitly after the pop up is loaded
        selectedOptionActual = selectedOption.value;
        this.addEditActivityForm.patchValue({
          WorkCentreId: null
        });

        this.dropdownService.getWorkingCentresByCompany(selectedOptionActual, wcNameSubString, 100, '', isSearchMode).subscribe(response => {

          this.workingCentres$ = this.dropdownService.WrappedToDropDownList(response, 'centreName', 'id');
          const defaults = response.filter(e => e.isDefault);
          if (defaults.length === 1) {
            this.setDefaultWorkCenter(defaults[0].id);
          }

        }, error => {
          this.errorHandler.handle(error);
        });
      }
    } else {
      this.workingCentres$ = null;
    }
    this.enableMultipleActivityPopUpBtn();
    //this.enableSubmitBtn();
  }

  private setDefaultWorkCenter(defalut: string): void {
    const workCenter = this.workingCentres$.filter(c => c.value === defalut);
    const workCenterId = workCenter ? workCenter[0].value : null;
    this.addEditActivityForm.patchValue({
      WorkCentreId: workCenterId
    });
  }

  saveActivity(addAnotherActivity = false) {
    const activityDate = this.addEditActivityForm.value.ActivityDate;
    const dtStart = new Date(this.addEditActivityForm.value.ActivityStartTime);
    const dtFinish = this.addEditActivityForm.value.ActivityEndTime == null ?
      null : new Date(this.addEditActivityForm.value.ActivityEndTime);
    const activityTimeStr = ('0' + dtStart.getHours()).slice(-2) + ':' + ('0' + dtStart.getMinutes()).slice(-2) + ':00';
    const actualMonth = activityDate.getMonth() + 1;

    // Get activity date in YYYYMMdd format
    const activityDateStr = activityDate.getFullYear() + ('0' + actualMonth).slice(-2) + ('0' + activityDate.getDate()).slice(-2);
    let actualActivityFinishTime: Date = null, actualActivityStartTime: Date = null;

    /*NOTE:Client requirement=> Status type "11" is not available in client DB.Therefore send "01" instead*/
    const actualStatusTypeId: string = this.addEditActivityForm.value.StatusTypeId === '11' ?
      '01' : this.addEditActivityForm.value.StatusTypeId;

    if (this.addEditActivityForm.value.ActivityStartTime !== null) {
      const [hours, minutes, seconds] = activityTimeStr.split(':');
      actualActivityStartTime = new Date(activityDate.getFullYear(), activityDate.getMonth(),
        activityDate.getDate(), +hours, Number(minutes), Number(seconds));
    }

    if (dtFinish !== null) {
      const activityFinishTimeStr = ('0' + dtFinish.getHours()).slice(-2) + ':' + ('0' + dtFinish.getMinutes()).slice(-2) + ':00';
      const [hours, minutes, seconds] = activityFinishTimeStr.split(':');
      actualActivityFinishTime = new Date(dtFinish.getFullYear(), dtFinish.getMonth(), dtFinish.getDate(), +hours, Number(minutes), 0);
    } else {
      actualActivityFinishTime = null;
    }

    this.activityRoot = new ActivityRoot();
    this.activityDetails = new EspecialidadTecnica();
    this.activityUserDetails = new UsuarioUpsert();

    if(this.selectedCompanyId != this.addEditActivityForm.value.CompanyId){
      this.addEditActivityForm.value.CompanyId = this.selectedCompanyId;
    }
    this.activityDetails = {
      tipo_especialidad: this.addEditActivityForm.value.ActivityTypeId,
      evento_estado: actualStatusTypeId,
      tiempo_estimado: 0,
      hora_inicio: this.addEditActivityForm.value.ActivityStartTime,
      hora_fin: this.activityService.getActivityFinishTime(actualActivityStartTime, actualActivityFinishTime, Number(this.addEditActivityForm.value.Duration)),
      empresa_id: this.addEditActivityForm.value.CompanyId,
      empresa_centro_id: this.addEditActivityForm.value.WorkCentreId
    };

    this.activityUserDetails = {
      fechaHora: this.addEditActivityForm.value.ActivityDate,
      ipOrigen: this.clientReqIPAddress,
      objectId2: '' // TODO: Not yet clarified.
    };

    this.activityRoot = {
      id: this.isEdit ? this.addEditActivityForm.value.Id : null,
      alias: this.addEditActivityForm.value.Alias,
      descripcion: this.addEditActivityForm.value.Description,
      fecha: activityDateStr,
      hora: activityTimeStr.replace(':', '').replace(':', ''), // Replace ':' with empty string =>should be in HHmmss format 
      user_assigned: this.addEditActivityForm.value.AssignedUserId,
      especialidadTecnica: this.activityDetails,
      usuario_upsert: this.activityUserDetails
    };

    if (this.addEditActivityForm.value.Id == null || this.addEditActivityForm.value.Id === '') {
      this.activityRoot.id = null;
    }   
    this.addActivity(addAnotherActivity);
    this.calculateDuration();
  }

  addActivity(addAnotherActivity = false) {
    const saveType: string = (this.activityRoot.id != null && this.activityRoot.id !== '') ? 'UPDATE Action' : 'INSERT Action';
    let responseParam = '', committedAction = '';
    this.spinner.show();

    this.dataSharingService.currentActuacionDataObject=this.activityRoot;
    this.activityService.saveActivity(this.activityRoot).then(response => {
      responseParam = JSON.stringify(response);
      this.spinner.hide();

      // Check for error
      if (response.webError != null) {
        this.activitySaveState = false;
        committedAction = saveType + ' failed';
        this.errorHandler.handle(response.webError.messageToUser, true);
      } else {
        this.activitySaveState = true;
        committedAction = saveType + ' success';

        //this.filterService.filterObject.id_actuacionestado=this.activityRoot.especialidadTecnica.evento_estado;
        if (addAnotherActivity) {
          this.filterService.setFilterValuesForAPI();
          this.activityService.filterValues = this.filterService.filterObject;
          //this.activityService.fetchActivityList();
          this.AddNextActivity();
        } else {
          this.addActivityModal.close();
        }

        this.toastrService.success('Guardado exitosamente', 'Éxito');
      }
      const indexedDBRecord = {
        userId: JSON.parse(localStorage.getItem('loggeduser')).id,
        activityId: this.activityRoot.id, loggedInTime: '',
        loggedOutTime: '', committedAction: committedAction,
        requestParam: JSON.stringify(this.activityRoot),
        responseParam: responseParam,
        logDate: this.logActivityService.getDateInCustomFormat(new Date())
      };
      this.logActivityService.saveDataToIndexedDB('useractivity', indexedDBRecord);
      return response;

    }, error => {

      responseParam = JSON.stringify(error);
      const indexedDBRecord = {
        userId: JSON.parse(localStorage.getItem('loggeduser')).id,
        activityId: this.activityRoot.id, loggedInTime: '',
        loggedOutTime: '', committedAction: committedAction,
        requestParam: JSON.stringify(this.activityRoot),
        responseParam: responseParam,
        logDate: this.logActivityService.getDateInCustomFormat(new Date())
      };
      this.logActivityService.saveDataToIndexedDB('useractivity', indexedDBRecord);
      this.spinner.hide();
      this.errorHandler.handle(error);

    });
  }

  deleteActivity() {

    if (this.addEditActivityForm.value.Id != null && this.addEditActivityForm.value.Id !== '') {

      Swal.fire({
        title: '¿Estás seguro de borrar la acción?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Comienza a borrar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {

          let committedAction = '';

          const deleteActivityObj = {
            id: this.addEditActivityForm.value.Id,
            usuario_upsert: {
              userId: JSON.parse(localStorage.getItem('loggeduser')).id,
              fechaHora: new Date(),
              ipOrigen: this.clientReqIPAddress,
              objectId2: null
            }
          };

          this.spinner.show();
          this.activityService.deleteActivity(deleteActivityObj).subscribe(response => {

            this.spinner.hide();
            if (response.webError != null) // Check for error from APIs
            {
              committedAction = 'DELETE failed';
              this.errorHandler.handle(response.webError.messageToUser, true);
            } else {
              if (response.data.eliminado) // Check whether deletion was successful
              {
                //this.activityService.fetchActivityList();
                committedAction = 'DELETE success';
                this.addActivityModal.close();
                this.toastrService.success('Borrado exitosamente', 'Éxito');
              } else {
                this.errorHandler.handle('No se pudo borrar la acción. Se produjo un error.', true);
              }
            }

            const indexedDBRecord = {
              userId: JSON.parse(localStorage.getItem('loggeduser')).id, activityId: deleteActivityObj.id,
              loggedInTime: '', loggedOutTime: '', committedAction: committedAction, requestParam: JSON.stringify(deleteActivityObj),
              responseParam: JSON.stringify(response), logDate: this.logActivityService.getDateInCustomFormat(new Date())
            };
            this.logActivityService.saveDataToIndexedDB('useractivity', indexedDBRecord);
          }, error => {
            const indexedDBRecord = {
              userId: JSON.parse(localStorage.getItem('loggeduser')).id, activityId: deleteActivityObj.id,
              loggedInTime: '', loggedOutTime: '', committedAction: committedAction, requestParam: JSON.stringify(deleteActivityObj),
              responseParam: error, logDate: this.logActivityService.getDateInCustomFormat(new Date())
            };
            this.logActivityService.saveDataToIndexedDB('useractivity', indexedDBRecord);
            this.spinner.hide();
            this.errorHandler.handle(error);
          });
        }
      });
    }
  }

  getActivityClass(statusType: string) {
    if (statusType === null) {
      return null;
    }

    let result: string = null;

    switch (statusType.toLowerCase().trim()) {
      case 'pendiente de confirmar':
        result = 'pending-confirmation';
        break;
      case 'confirmada-pendiente de realizar':
        result = 'confirmed-pending';
        break;
      case 'pendienter':
        result = 'confirmed-pending';
        break;
      case 'pendiente':
        result = 'confirmed-pending';
        break;
      case 'realizada-pendiente de validar':
        result = 'done-pending-validation';
        break;
      case 'realizada y Validada':
        result = 'done-validated';
        break;
      case 'validado':
        result = 'done-validated';
        break;
      case 'cancelada':
        result = 'cancelled';
        break;
      case 'cancelado':
        result = 'cancelled';
        break;
    }
    return result;
  }

  confirmStatusChange(event: any) {
    if (this.currentSelectedStatus !== this.addEditActivityForm.value.StatusTypeId) {

      Swal.fire({
        title: 'Estás seguro?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Cambiar Estado',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.currentSelectedStatus = this.addEditActivityForm.value.StatusTypeId;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.addEditActivityForm.patchValue({
            StatusTypeId: this.currentSelectedStatus
          });
        }
      });
    }
  }

  openMultipleActivityPopup() {
    this.saveActivity();
    this.emailAddress = [];
    this.dropdownService.getCompanyCodigo(this.activityService.companyId).subscribe(res => {
      this.companyCodigo = res.codigo;
      this.dropdownService.getBulkEmail(this.companyCodigo).subscribe(response => {
        for (var i = 0; i < response.length; i++) {
          const emailObj = { display: response[i].contactEmail, value: response[i].contactEmail }
          this.emailAddress.push(emailObj);
        }
      }, error => {
        this.errorHandler.handle(error);
      })
    }, error => {
      this.errorHandler.handle(error);
    })

    this.dropdownService.emailAddresses = this.emailAddress;
    const modalRef = this.modalService.open(AddMultipleActivitiesComponent, this.modalOptions);
   
    modalRef.componentInstance.ActivityFormGroupWizard1.patchValue({
      ParentActivityId: this.addEditActivityForm.value.Id,
      CompanyId: this.addEditActivityForm.value.CompanyId,
      CompanyName: this.addEditActivityForm.value.CompanyName,
      WorkCentreId: this.addEditActivityForm.value.WorkCentreId,
      WorkCentreName: this.addEditActivityForm.value.WorkCentreName,
      AssignedUserId: this.addEditActivityForm.value.AssignedUserId,
      AssignedUserName: this.addEditActivityForm.value.AssignedUserName,
      ActivityDate: this.addEditActivityForm.value.ActivityDate,
      ActivityTime: this.addEditActivityForm.value.ActivityStartTime,
      Duration: Number(this.addEditActivityForm.value.Duration),
      Description: this.addEditActivityForm.value.Description,
      VisitActivityId: this.addEditActivityForm.value.Id,
      ActivityTypeId: this.addEditActivityForm.value.ActivityTypeId,
      ActivityFinishTime: this.addEditActivityForm.value.ActivityEndTime
    });

    // 20071120163100-0000001-0001-W - Cataln Language Id, If it ain't catalan then default to spanish
    modalRef.componentInstance.selectedCompanyLanguage = this.languageId === '20071120163100-0000001-0001-W' ? 'ca' : 'es';

    modalRef.componentInstance.SelectedParteDeVisitaState = this.SelectedParteDeVisitaState;
    modalRef.componentInstance.SelectedReferenceNo = this.SelectedVisitaReferenceNo;
    modalRef.componentInstance.userDetailsId = this.addEditActivityForm.value.Id;

    modalRef.result.then((_close) => {
      this.filterService.setFilterValuesForAPI();
      this.activityService.filterValues = this.filterService.filterObject;
      this.activityService.fetchActivityList();
    }, (_dismiss) => {
      this.multipleActivityService.selectedActivity.length = 0;
    });
  }

  openMemoriaAnualPopup() {
    
    const activityTypeName = this.addEditActivityForm.value.ActivtyTypeName.toLowerCase();
    if(activityTypeName.includes('memoria anual') && (
      activityTypeName.includes('envío/entrega') || activityTypeName.includes('elaboración')
    )){
      const modalRef = this.modalService.open(MemoriaAnualWithQuizPopupComponent, this.modalOptions);
      modalRef.componentInstance.companyData = this.addEditActivityForm.value;
      modalRef.componentInstance.userDetailsId = this.addEditActivityForm.value.Id;
    }
    else {
      const modalRef = this.modalService.open(MemoriaAnualPopupComponent, this.modalOptions);
      modalRef.componentInstance.companyData = this.addEditActivityForm.value;
    }
    // modalRef.result.then((close) => {
    // });
  }

  openPreventionPlanPopup() {
    const modalRef =  this.modalService.open(PreventionPlanPopupComponent, this.modalOptions);
    modalRef.componentInstance.companyData = this.addEditActivityForm.value;
    this.addActivityModal.close();
  }

  openShippingSmePopup(): void {
    this.saveActivity();
    const modalRef = this.modalService.open(AnnualShippingMicroSMEComponent, this.modalOptions);
    modalRef.componentInstance.userDetailsId = this.addEditActivityForm.value.Id;

    const formValue = this.addEditActivityForm.value;    

    let timeString = new Date(formValue.ActivityStartTime).toString();
    if (timeString) {
      timeString = timeString.split(' ')[4];
    }

    const parentDate: Date = formValue.ActivityDate;
    const actualMonth: number = parentDate.getMonth() + 1;
    const activityDateString = `${parentDate.getDate().toString()}/${actualMonth.toString()}/${parentDate.getFullYear().toString()}`;

    modalRef.componentInstance.selectedActivity = {
      ActivityId: formValue.Id,
      CompanyId: formValue.CompanyId,
      CompanyName: formValue.CompanyName,
      WorkCentreId: formValue.WorkCentreId,
      WorkCentreName: formValue.WorkCentreName,
      AssignedUserId: formValue.AssignedUserId,
      AssignedUserName: formValue.AssignedUserName,
      ActivityDate: activityDateString,
      ActivityStartTime: timeString,
      Duration: Number(formValue.Duration),
      Description: formValue.Description,
      ActivityTypeId: formValue.ActivityTypeId,
      ActivtyTypeName: formValue.ActivtyTypeName,
      UserId: JSON.parse(localStorage.getItem('loggeduser')).id
    };

    // 20071120163100-0000001-0001-W - Cataln Language Id, If it ain't catalan then default to spanish
    modalRef.componentInstance.selectedCompanyLanguage = this.languageId === '20071120163100-0000001-0001-W' ? 'ca' : 'es';

    modalRef.result.then((close) => {
      this.filterService.setFilterValuesForAPI();
      this.activityService.filterValues = this.filterService.filterObject;
      this.activityService.fetchActivityList();
    });
  }

  setAssignedUser(selectedOption: any) {
    this.addEditActivityForm.value.AssignedUserId = selectedOption.value;
  }

  // To hide the 'Parte De Visita' button(The button to get multiple activity Pop Up)
  hidePopupButtons(selectedOption: any) {
    localStorage.setItem("selectedTipoActuation", selectedOption.value);
    this.isVisit = (String(selectedOption.label).toLowerCase().includes('visita') && this.isEdit);
    if((selectedOption.value === '20192503000000-0000000-0046-W' && this.isEdit) || (selectedOption.value === "20151013114725-6267782-0059-W" && this.isEdit)) {
      this.isAnualProgramme = true;
    } 

    if (selectedOption.value == '20192503000000-0000000-0046-W') {
      this.showEnvio = false;
    } else {
      this.showEnvio = true;
    }

    if (selectedOption.value == '20151013114725-6267782-0059-W') {
      this.showElaboracion = false;
    } else {
      this.showElaboracion = true;
    }

  }

  // To enable the 'Parte De Visita' button(The button to get multiple activity Pop Up)
  enableMultipleActivityPopUpBtn() {
    if (this.addEditActivityForm.value.WorkCentreId == null) {
      this.isMultipleActivityPopUpBtnEnabled = false;
    } else {
      this.isMultipleActivityPopUpBtnEnabled = true;
    }

  }

  // To enable the 'Submit' button
  // enableSubmitBtn() {
  //   this.addEditActivityForm.value.Duration = this.addEditActivityForm.value.Duration == undefined ? 0 : this.addEditActivityForm.value.Duration;

  //   if (this.addEditActivityForm.value.WorkCentreId == null && Number(this.addEditActivityForm.value.Duration) <= 0 && this.addEditActivityForm.value.ActivityEndTime == null) {
  //     this.isSubmitBtnEnabled = false;
  //   } else {
  //     this.isSubmitBtnEnabled = true;
  //   }
  // }

  //#region "Limited value loading for main dropdowns "

  // Company
  loadTopMatchingCompanies(companyName: any) {
    // Trigger the loading only if string length is>=4
    if (companyName.length >= 4) {
      const companyId = this.isEdit ? this.addEditActivityForm.value.CompanyId : '';
      this.dropdownService.getCompanies(companyName, 100, companyId, true).subscribe(response => {
        this.companies$ = this.dropdownService.WrappedToDropDownList(response, 'name', 'companyId');
      }, error => {
        this.errorHandler.handle(error);
      });
    } else {
      this.companies$ = null;
      this.workingCentres$ = null;
      this.addEditActivityForm.patchValue({
        CompanyId: null,
        WorkCentreId: null
      });
    }
  }

  onKeyUpCompany(companyNameTarget: any) {
    if (companyNameTarget.target.value != null) {
      this.companySubject.next(companyNameTarget.target.value);
    }
  }

  // workCentres
  loadTopMatchingWorkcentres(wcNameTarget: any) {
    // Trigger the loading only if string length is>=4
    if (wcNameTarget.length >= 4) {
      this.getCompanyWorkCentres(this.addEditActivityForm.value.CompanyId, true, wcNameTarget, true);
    } else {
      this.workingCentres$ = this.workingCentres$ ? this.workingCentres$ : null;
      this.addEditActivityForm.patchValue({
        WorkCentreId: null
      });
    }
  }

  onKeyUpCenter(centerNameTarget: any) {
    if (centerNameTarget.target.value != null) {
      this.centerSubject.next(centerNameTarget.target.value);
    }
  }
  // acivity types
  loadTopMatchingActivityTypes(activityTypeNameTarget: any) {
    // Trigger the loading only if string length is>=4
    if (activityTypeNameTarget.length >= 4) {
      const actTypeId = this.isEdit ? this.addEditActivityForm.value.activityTypeId : '';
      this.dropdownService.getActivityTypes(activityTypeNameTarget, 100, actTypeId, false, true).subscribe(response => {
        this.activityTypes$ = this.dropdownService.WrappedToDropDownList(response, 'name', 'activityTypeId');
      }, error => {
        this.errorHandler.handle(error);
      });
    } else {
      this.activityTypes$ = null;
      this.addEditActivityForm.patchValue({
        ActivityTypeId: null
      });
    }
  }
  onKeyUpTypeOfActivity(typeOfActivityTarget: any) {
    if (typeOfActivityTarget.target.value != null) {
      this.typeOfActivitySubject.next(typeOfActivityTarget.target.value);
    }
  }
  loadTopMatchingUsers(usernameSubStr: any) {
    // Trigger the loading only if string length is>=4
    if (usernameSubStr.length >= 4) {
      const userId = this.isEdit ? this.addEditActivityForm.value.AssignedUserId : '';
      this.dropdownService.getUsersLimited(usernameSubStr, 100, userId, true).subscribe(response => {
        this.users$ = this.dropdownService.WrappedToDropDownList(response, 'fullName', 'id');
      }, error => {
        this.errorHandler.handle(error);
      });
    } else {
      this.users$ = null;
      this.addEditActivityForm.patchValue({
        AssignedUserId: null
      });
    }
  }

  onKeyUpUser(userTarget: any) {
    if (userTarget.target.value != null) {
      this.userSubject.next(userTarget.target.value);
    }
  }
  //#endregion

  private AddNextActivity(): void {
    const time = this.addEditActivityForm.value.ActivityStartTime;
    const date = this.addEditActivityForm.value.ActivityDate;

    if (time) {
      const duration = Number(this.addEditActivityForm.value.Duration);
      let timeMoment = moment(time);
      const dateMoment = moment(date);

      timeMoment = timeMoment.set({ 'year': dateMoment.get('year'), 'month': dateMoment.get('month'), 'date': dateMoment.get('date') });

      const updatedTime = timeMoment.add(duration, 'minutes');
      this.addEditActivityForm.get('ActivityStartTime').patchValue(updatedTime.toDate());
      //this.addEditActivityForm.get('Duration').patchValue(15);
      //this.setDuration();

      this.addEditActivityForm.get('ActivityDate').patchValue(updatedTime.toDate());
    }
  }

  private getParteDeVisitaState(): number {
    return Math.floor(Math.random() * (2 - 0 + 1)) + 0;
  }

}

import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs-compat';
import { MasterDetailsApiService } from '../masterdetailsapi.service';
import { MemoriaAnualQuiz } from 'src/app/models/memoria-anual-quiz.model';
import autonomoquiz from "../_quizes/autonomo.json";
import empresaquiz from "../_quizes/empresa.json";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemoriaAnualService {
  public ActivityFormGroupWizard1: FormGroup;
  public ActivityFormGroupWizard2: FormGroup;
  public ActivityFormGroupWizard3: FormGroup;
  public ActivityFormGroupWizard4: FormGroup;
  public Questions: [];
  constructor(private apiService: MasterDetailsApiService,public fb: FormBuilder, private httpClient: HttpClient) { }

  getAllClosedContracts(contractsRequest: any) : Observable<any> {
    return this.apiService.post('WorkCentre/GetAllClosedContracts' , contractsRequest);
  }

  getAllContracts(contractsRequest: any) : Observable<any> {
    return this.apiService.post('WorkCentre/GetAllContracts' , contractsRequest);
  }

  getContactPersons(workCentreId: string) {
    return this.apiService.get('Workforce/GetContactPersons/' + workCentreId);
  }
  
  getWorkCenterData(workCentreId: string) {
    return this.apiService.get('WorkCentre/GetWorkCenterData/' + workCentreId);
  }

  ngOnInit(): void {
    this.ActivityFormGroupWizard1 = this.fb.group({
      TemplateLanguage: ['',Validators.required],
      Inicio:[],
      Fin:[],
      Implicacions:['Baja'],
      DateRange:[]
    });
    this.ActivityFormGroupWizard4 = this.fb.group({
      TemplateLanguage: ['',Validators.required],
      Inicio:[],
      Fin:[],
      TypeOfEmpressa:["1",Validators.required],
      DateRange:[]
    });
  }

 
  public downloadMemoriaAttachment(requestModel: any): Observable<any> {
    return this.apiService.post('Activity/ExportPdf', requestModel);
  }
  public downloadMemoriaWithQuizAttachment(requestModel: any): Observable<any> {
    return this.apiService.post('Activity/ExportMemAnualWithQuizPdf', requestModel);
  }

  public getdisciplinasyactuaciones(requestModel: any) : Observable<any>{
    let url = 'https://api.geseme.com/erp/empresa/memoria/disciplinasyactuaciones';
    return this.httpClient.post(url, requestModel);
  }
  

  public generateMemoriaAnualQuiz(type: number) : MemoriaAnualQuiz[] {
    let questions = [];
    // Empresa
    if(type == 1){
      this.Questions = JSON.parse(JSON.stringify(empresaquiz));
      questions = JSON.parse(JSON.stringify(this.Questions));
    }
    // Autonomo
    else{
      this.Questions = JSON.parse(JSON.stringify(autonomoquiz));
      questions = JSON.parse(JSON.stringify(this.Questions));
    }
    return questions
  }

  // get user details and signature
  public getUserDetailsAndSignature(reqObject: any): Observable<any>{
    let  url = `${environment.gesemeApiUrl}erp/actuacion/firma`
    return this.httpClient.post(url, reqObject)
  }

}
